<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        prop="fullName"
                        label="姓名"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.fullName"
                            placeholder="请输入姓名"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="avatar"
                        label="照片"
                        label-width="12em"
                    >
                        <upload
                            :validate-md5="true"
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.avatar"
                            tips=""
                        />
                    </el-form-item>
                    <el-form-item
                        prop="labelName"
                        label="标签"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.labelName"
                            placeholder="请输入标签"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="idCardNo"
                        label="身份证号"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.idCardNo"
                            placeholder="请输入身份证号"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="mobile"
                        label="联系电话"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.mobile"
                            placeholder="请输入联系电话"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="sex"
                        label="性别"
                        label-width="12em"
                    >
                        <dictionaries-picker
                            type="sex"
                            v-model="formModel.sex"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="birthday"
                        label="出生日期"
                        label-width="12em"
                    >
                        <el-date-picker
                            v-model="formModel.birthday"
                            type="date"
                            placeholder="请选择出生日期"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="graduatedSchool"
                        label="毕业学校"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.graduatedSchool"
                            placeholder="请输入毕业学校"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="jobTitle"
                        label="职务级别"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.jobTitle"
                            placeholder="请输入职务级别"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item
                        prop="personalIntroduction"
                        label="个人介绍"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.personalIntroduction"
                            image-upload-tips=""
                        />
                    </el-form-item>
                    <el-form-item
                        prop="workExperience"
                        label="工作经验"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.workExperience"
                            image-upload-tips=""
                        />
                    </el-form-item>
                    <el-form-item
                        prop="educationalExperience"
                        label="教育经历"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.educationalExperience"
                            image-upload-tips=""
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        prop="reservationPrice"
                        label="预约价格"
                        label-width="12em"
                    >
                        <el-input-number
                            controls-position="right"
                            v-model="formModel.reservationPrice"
                            :precision="2"
                            :min="0"
                            placeholder="请输入"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="doctorStatus"
                        label="医生状态"
                        label-width="12em"
                    >
                        <dictionaries-picker
                            type="doctorStatus"
                            v-model="formModel.doctorStatus"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item
                        prop="remarks"
                        label="备注"
                        label-width="12em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.remarks"
                            placeholder="请输入备注"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item
                        label-width="12em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm()"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'StationEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                avatar: [],
                fullName: '',
                labelName: '',
                idCardNo: '',
                mobile: '',
                sex: '',
                birthday: '',
                graduatedSchool: '',
                jobTitle: '',
                personalIntroduction: '',
                workExperience: '',
                educationalExperience: '',
                reservationPrice: undefined,
                doctorStatus: '',
                remarks: '',
            },
            // 表单校验规则
            formRules: {
                fullName: {
                    required: true,
                    message: '请输入',
                },
                avatar: {
                    type: 'array',
                    required: true,
                    message: '请选择',
                },
                idCardNo: {
                    required: true,
                    message: '请输入',
                },
                mobile: {
                    required: true,
                    message: '请输入',
                },
                sex: {
                    required: true,
                    message: '请输入',
                },
                reservationPrice: {
                    required: true,
                    message: '请选择',
                },
                doctorStatus: {
                    required: true,
                    message: '请选择',
                },
            },
            regionApi: this.$api.Rs.Region,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pe.Doctor.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDetail() {
            this.$api.Pe.Doctor.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDetail();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
